/** @jsx jsx */

import { Link } from 'gatsby'
import { slide as Menu } from 'react-burger-menu'
import './burger.css'
import { Flex, jsx } from 'theme-ui'
import { useMediaQuery } from 'react-responsive'

export const BurgerMenu = ({ page }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const background = page === 'home' ? (isMobile ? 'white' : 'black') : 'black'
  return (
    <Menu
      right
      width={150}
      sx={{
        position: 'flex',
        bg: 'grey',
        display: 'flex',
        flexDirection: 'column',
        '.bm-item-list': {
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        },
      }}
      styles={{
        bmBurgerBars: {
          background,
        },
      }}
    >
      <NavButton link="/" text="Home" />
      <NavButton link="/resources" text="Resources" />
      <NavButton link="/events" text="Events" />
      <NavButton link="/about" text="About" />
      <NavButton link="/contact" text="Contact" />
    </Menu>
  )
}

const NavButton = ({ link, text }) => {
  return (
    <Link
      to={link}
      activeClassName="active"
      sx={{
        textDecoration: 'none',
        fontFamily: 'Futura Medium',
        fontSize: '20px',
        color: 'white',
        '&.active': {
          color: 'primary.one',
        },
      }}
    >
      {text}
    </Link>
  )
}
