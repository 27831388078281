import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Logo = ({ white = true }: { white: boolean }) => {
  const data = useStaticQuery(
    graphql`
      query {
        blueLogo: file(relativePath: { eq: "logo-blue.png" }) {
          childImageSharp {
            # Specify a fixed image and fragment.
            # The default width is 400 pixels
            fixed(width: 50, height: 50) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        whiteLogo: file(relativePath: { eq: "logo-white.png" }) {
          childImageSharp {
            # Specify a fixed image and fragment.
            # The default width is 400 pixels
            fixed(width: 50, height: 50) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  return (
    <Img
      fixed={
        white
          ? data.whiteLogo.childImageSharp.fixed
          : data.blueLogo.childImageSharp.fixed
      }
      alt="Migration in Harmony logo"
    />
  )
}

export default Logo
