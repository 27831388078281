import React from 'react'

import Header from '@components/Header'
import Footer from '@components/Footer'
import { Box } from 'theme-ui'
import GlobalStyles from '../../util/style/GlobalStyles'

const Layout: React.FC<{ page: string }> = ({ children, page }) => (
  <ErrorBoundary>
    <GlobalStyles />
    {/* Render home page specially with unique heading */}
    {page !== 'home' && <Header />}
    <Box
      sx={{
        m: '0 auto',
      }}
    >
      {children}
      <Footer />
    </Box>
  </ErrorBoundary>
)

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })
    Sentry.configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
    })
    Sentry.captureException(error)
  }

  render() {
    if (this.state.error) {
      // render fallback UI
      return <h1>Something went wrong!</h1>
    } else {
      // when there's not an error, render children untouched
      return this.props.children
    }
  }
}

export { Layout }
