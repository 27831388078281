/** @jsx jsx */

import { Link } from 'gatsby'
import Logo from '@components/Logo'
import { useMediaQuery } from 'react-responsive'

import { Button, Flex, jsx } from 'theme-ui'
import { BurgerMenu } from './BurgerMenu'

export const NavButton = ({ link, text }: { link: string; text: string }) => (
  <Link
    to={link}
    activeClassName="active"
    sx={{
      textDecoration: 'none',
      fontFamily: 'Futura Medium',
      fontSize: ['8px', '18px'],
      color: 'grey',
      pr: [4],
      py: 3,
      '&.active': {
        color: 'primary.one',
      },
    }}
  >
    {text}
  </Link>
)

export const JoinNavButton = ({ color }: { color?: string }) => (
  <Link to="/contact" css={{ textDecoration: 'none' }}>
    <Button
      sx={{
        bg: color || 'primary.one',
        color: color === 'white' ? 'primary.one' : 'white',
      }}
    >
      Join our network
    </Button>
  </Link>
)

const Header = ({ page }: { page?: string }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 1050px)' })

  return isMobile ? (
    <Flex as="header" px={4} pt={4} mb={4}>
      <Flex mr="auto" p={3}>
        <Link to="/" css={{ textDecoration: 'none' }}>
          <Logo white={page === 'home'} />
        </Link>
      </Flex>
      <BurgerMenu page={page} />
    </Flex>
  ) : (
    <Flex as="header" px={4} pt={4} mb={4}>
      {/* https://stackoverflow.com/questions/23621650/how-to-justify-a-single-flexbox-item-override-justify-content */}
      <Flex mr="auto" p="10px">
        <Link to="/" css={{ textDecoration: 'none' }}>
          <Logo white={page === 'home'} />
        </Link>
      </Flex>
      <NavButton link="/" text="Home" />
      <NavButton link="/resources" text="Resources" />
      <NavButton link="/events" text="Events" />
      <NavButton link="/about" text="About" />
      <NavButton link="/contact" text="Contact" />
      <JoinNavButton />
    </Flex>
  )
}

export default Header
