import React from 'react'
import { Box, Flex, Text, Link } from 'theme-ui'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { JoinNavButton } from '@components/Header'

const Footer = () => {
  return (
    <Flex
      as="footer"
      sx={{
        flexDirection: ['column', 'row'],
        justifyContent: 'space-around',
        alignSelf: 'flex-end',
        bg: 'primary.two',
        p: [5],
      }}
    >
      <Social />
      <NSFLogo />
      <Newsletter />
    </Flex>
  )
}

const Newsletter = () => {
  return (
    <Flex
      sx={{
        flexDirection: ['column'],
        justifyContent: 'space-between',
        mt: [4, 0],
      }}
    >
      <JoinNavButton color="white" />

      <Text
        variant="h3"
        sx={{
          color: 'white',
          mt: '50px',
        }}
      >
        MiH-RCN, 2020. All rights reserved.
      </Text>
    </Flex>
  )
}

const Social = () => {
  const links = [
    {
      key: 0,
      link: 'https://twitter.com/mih_rcn',
      label: 'Twitter',
    },
    {
      key: 1,
      link:
        'https://join.slack.com/t/migrationinharmony/shared_invite/zt-f8pkjnyc-9qmRAja_tmJkdoDdhM6WlA',
      label: 'Slack',
    },
  ]
  return (
    <Flex
      sx={{
        flexDirection: ['column'],
      }}
    >
      <Text variant="h3" sx={{ color: 'white', mb: 3 }}>
        Social networking
      </Text>
      {links.map(({ link, label, key }) => (
        <Box key={key} my={2}>
          <Link
            href={link}
            sx={{
              fontSize: [3],
              color: 'white',
              textDecoration: 'none',
              fontFamily: 'Futura Medium',
            }}
          >
            {label}
          </Link>
        </Box>
      ))}
    </Flex>
  )
}

const NSFLogo = () => {
  const data = useStaticQuery(
    graphql`
      query {
        heroImage: file(relativePath: { eq: "NSF_logo.png" }) {
          childImageSharp {
            fixed(height: 100, width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )
  return (
    <a href="https://www.nsf.gov/">
      <Img
        fixed={data.heroImage.childImageSharp.fixed}
        key={data.heroImage.childImageSharp.fixed.src}
        alt="Migration Harmony hero collage"
      />
    </a>
  )
}

export default Footer
